import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BannerLinks,
  Breadcrumbs,
  BlogSlider,
  VideoAndText,
  Appointment
} from '../../components'

import BannerImage from '../../assets/technologie.jpg'
import boxes from '../../components/BannerLinks/DefaultBox'
import cards from '../../components/BlogSlider/DefaultCards'

import { generateBreadcrumbsLinks } from '../../utils/index'

import Georg from '../../assets/georg_neu.png'

const Girlsday2022 = () => {
  const links = generateBreadcrumbsLinks(
    '/blog/girlsday-2022',
    'Girls Day 2022'
  )
  return (
    <Layout>
      <Helmet
        title="Videodreh zum Girls Day 2022"
        meta={[
          {
            name: 'description',
            content:
              'Anlässlich des "Girls Day 2022" wurde bei holzweg ein Video gedreht. Lehrling Anja berichtet über ihre Ausbildung.'
          },
          {
            name: 'keywords',
            content:
              'holzweg, Girls Day, Girls Day 2022, websites, IT, Lehre'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/anja-friedensdorf-interview-4e95aba57d21be81f7be56a4ba6a705d.jpg'
          },
          {
            property: 'og:description',
            content:
              'Anlässlich des "Girls Day 2022" wurde bei holzweg ein Video gedreht. Lehrling Anja berichtet über ihre Ausbildung.'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/anja-friedensdorf-interview-4e95aba57d21be81f7be56a4ba6a705d.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Girls Day 2022
          </span>
        }
      />
      <Breadcrumbs backLinkAlias=" " links={links}/>
      <VideoAndText
        YTvideo="mqcsR9tjWKk"
        headline="Videodreh zum Girls Day"
        text='Im Rahmen des „Girls Day 2022“ wurde ein sehr tolles Video über die Lehre "Applikationsentwicklung / Coding"
        bei holzweg gedreht. Bei uns in Ausbildung ist Anja Friedensdorf, welche in dem Beitrag die Vorteile
        von dem interessanten Beruf rund ums Programmieren erklärt und motiviert junge Mädchen in die Welt der IT
        hineinzuschnuppern.
        Der Girls‘ Day Tirol, wird von der amg-tirol umgesetzt und vom Land Tirol gefördert.'
      />

      <Appointment image={Georg} />
      <BlogSlider
        cards={cards}
        singleRow={true}
        title="Weitere Blogbeiträge:"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Girlsday2022
